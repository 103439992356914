<script>
export default {
  name: 'LoggerStatusIcon',
  props: {
    size: {
      type: String,
      required: false,
      default: '1x',
    },
    status: {
      type: String,
      required: true,
    },
    lastupdate: {
      type: Date,
      required: false,
    },
    alarm: {
      type: Boolean,
      required: false,
      default: false,
    },
    textpos: {
      type: String,
      required: false,
    },
    nolabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    textClass() {
      if (this.textpos && this.textpos === 'bottom') {
        return 'lead';
      }
      return 'ml-1';
    },
    iconClass() {
      if (this.textpos && this.textpos === 'bottom') {
        return 'mb-1';
      }
      return '';
    },
    colClass() {
      if (this.textpos && this.textpos === 'bottom') {
        return 'text-center';
      }
      if (this.nolabel) {
        return 'nolabel';
      }
      return '';
    },
    offline() {
      if (this.lastupdate) {
        const now = new Date();
        if ((now - this.lastupdate) > 60 * 1000 * 60) { // 60s * 1000ms * 60 min
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<template>
  <span>
    <!-- One status icon will be rendered -->
    <div v-if="status === 'OFFLINE' || this.offline" :class='colClass'>
      <fa-icon icon='minus-circle'
               :style="{ color: 'gray' }"
               :size='size'
               :class='iconClass'>
      </fa-icon>
      <span v-if='!nolabel' :class='textClass'>Offline</span>
    </div>
    <div v-else-if="status === 'RECORDING' && !alarm" :class='colClass'>
      <fa-icon icon='check-circle'
               :style="{ color: 'green' }"
               :size='size'
               :class='iconClass'>
      </fa-icon>
      <span v-if='!nolabel' :class='textClass'>Recording</span>
    </div>
    <div v-else-if="status === 'BUSY' && !alarm" :class='colClass'>
      <fa-icon icon='ellipsis-h'
               :style="{ color: 'red' }"
               :size='size'
               :class='iconClass'>
      </fa-icon>
      <span v-if='!nolabel' :class='textClass'>Busy</span>
    </div>
    <div v-else-if="status === 'WAITING' && !alarm" :class='colClass'>
      <fa-icon icon='ellipsis-h'
               :style="{ color: 'orange' }"
               :size='size'
               :class='iconClass'>
      </fa-icon>
      <span v-if='!nolabel' :class='textClass'>Waiting</span>
    </div>
    <div v-else-if="status === 'IDLE' && !alarm" :class='colClass'>
      <fa-icon icon='check-circle'
               :style="{ color: 'black' }"
               :size='size'
               :class='iconClass'>
      </fa-icon>
      <span v-if='!nolabel' :class='textClass'>Idle</span>
    </div>
    <div v-else-if="status === 'STARTUP' && !alarm" :class='colClass'>
      <fa-icon icon='chevron-circle-up'
               :style="{ color: 'blue' }"
               :size='size'
               :class='iconClass'>
      </fa-icon>
      <span v-if='!nolabel' :class='textClass'>Startup</span>
    </div>
    <div cols='auto' v-else-if='alarm' :class='colClass'>
      <fa-icon icon='exclamation-circle'
               :style="{ color: 'red' }"
               :size='size'
               :class='iconClass'>
      </fa-icon>
      <span v-if='!nolabel' :class='textClass'>Alarm</span>
    </div>
  </span>
</template>

<style scoped>
  .nolabel {
    display: inline;
  }
</style>
